import React, { useEffect, useState } from "react";
import {Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import LabProfile from "./labProfile";
import LabAdminLink from "./labAdminLink";
import ViewAppointmentsByLabCorporate from "./viewAppointmentsByLabCorporate";
 
  function LabProfileTab() {

  const { orgcode } = useParams();

  console.log("org",orgcode);

  const userRole = JSON.parse(localStorage.getItem("data")).role;
  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('labProfile');
  // const [selectedOrgcode, setSelectedOrgcode] = useState(orgcode);

  useEffect(() => {
  
  }, []);

  

  return (
    <div>
      <AdminDashboard />
      <br/>
              <br/>
              <br/>
      <br />


<div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="lab-profile-tab"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="labProfile" title="Lab Profile">
              {activeTab === 'labProfile' && (
                <LabProfile selectedOrgcode={orgcode} />
              )}
              </Tab>

              <Tab eventKey="appointments" title="Appointments">
                {activeTab === 'appointments' && (
                  <ViewAppointmentsByLabCorporate labcode={orgcode} />
                )}
              </Tab>

{userRole==='ROLE_SUPER' && (
              <Tab eventKey="adminLink" title="Admin Link">
              {activeTab === 'adminLink' && (
                <LabAdminLink selectedOrgcode={orgcode}/>
              )}
              </Tab>
              )}

            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default LabProfileTab;