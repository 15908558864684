import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import "./Stepper.css"; // Custom CSS for the stepper
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Modal, Button, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function BookAnAppointment() {
  const [labs, setLabs] = useState([]);
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [selectedLab, setSelectedLab] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [date, setDate] = useState("");
  // const [time, setTime] = useState("");
  const [step, setStep] = useState(1);
  const [loader, setLoader] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false); // State for warning modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [appointment, setappointment] = useState({
    date: "",
    // time: "",
    userid: "",
    labcode: "",
  });

  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const searchUrl = `/api/labdetails`;
      const response = await axios.get(searchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLabs(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const getData = async (pageNumber = 0) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const trans = await axios.get(`/api/labdetails/getAll`, {
        params: { page: pageNumber },
        headers: { Authorization: `Bearer ${token}` },
      });

      setLabs(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    if (labs.length > 0) {
      const filtered = labs.filter((lab) => {
        const labNameMatch = lab.labname?.toLowerCase().includes(searchTerm);
        const labCodeMatch = lab.labcode?.toLowerCase().includes(searchTerm);
        const cityMatch = lab.city?.toLowerCase().includes(searchTerm);
        const pincodeMatch = lab.pincode?.toString().toLowerCase().includes(searchTerm);
  
        return labNameMatch || labCodeMatch || cityMatch || pincodeMatch;
      });
      setFilteredLabs(filtered);
    }
  }, [searchTerm, labs]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  const handleBookAppointment = async () => {
    if (!date) {
      toast.warn("Please select a date");
      return;
    }
     // Perform date validation here
   const selectedDate = new Date(date);
   const currentDate = new Date();
   const twoDaysFromNow = new Date();
   twoDaysFromNow.setDate(currentDate.getDate() + 2);
 
   if (selectedDate < twoDaysFromNow || selectedDate.getDay() === 0) {
     toast.warn("Please select a weekday other than sunday and ensure the date is at least 2 days from today.")
     return;
   }

    setShowWarningModal(true); // Show the warning modal
    
  };

  const handleShowConfirmationModal = async () => {

    setShowConfirmationModal(true); // Show the warning modal
    
  };

  const confirmBookAppointment = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const userid = JSON.parse(localStorage.getItem("data")).userid;

    try {
      setLoader(true);
      await axios.post(
        "/api/employeeappointment/create",
        {
          userid: userid,
          labcode: selectedLab.labcode,
          date,
          // time,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Appointment Booked successfully");
      navigate('/users/employee-appointments');
    } catch (error) {
      setLoader(false);
      handleError(error);
    } finally {
      setShowConfirmationModal(false); // Close the modal
    }
  };

  const handleUseMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        const filtered = labs.filter(lab => {
          const distance = calculateDistance(latitude, longitude, lab.latitude, lab.longitude);
          console.log(`Distance to ${lab.labname}: ${distance.toFixed(2)} km`);
          return distance <= 10; // Filter labs within 10 km
        });
        setFilteredLabs(filtered);
      }, error => {
        console.error("Error getting location:", error);
        toast.error("Unable to retrieve your location.");
      });
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };
  
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <div className="row">
            <div className="col-md-12">
              <div className="card card-raised">
                <div className="card-body">
                <div className="card-title form-group d-flex align-items-center justify-content-between">
  <span>Choose Lab</span>
  <div className="d-flex align-items-center">
    <div className="form-group position-relative" style={{ width: '300px', marginRight: '10px' }}>
      <input
        type="search"
        className="form-control"
        placeholder="Search by lab name, code, city, pincode"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ paddingLeft: "2rem", width: "100%" }}
      />
      <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
        <FontAwesomeIcon icon={faSearch} />
      </span>
    </div>
    <Button variant="outline-primary" onClick={handleUseMyLocation}>
      <FontAwesomeIcon icon={faMapMarkerAlt} /> Use My Location
    </Button>
  </div>
</div>


                  <div className="row mt-5">
                  {filteredLabs.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (filteredLabs.map((lab) => (
                      <div className="col-md-4 mb-4" key={lab.id}>
                        <div
                          className={`card ${selectedLab && selectedLab.labcode === lab.labcode ? 'border-primary' : ''}`}
                          onClick={() => setSelectedLab(lab)}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={lab.image} className="card-img-top" alt={lab.name} />
                          <div className="card-body">
                            <h5 className="card-title mb-2">{lab.labname}</h5>
                            <p className="card-text">Lab Code: {lab.labcode}</p>
                            <p className="card-text">Contact Number: {lab.contact}</p>
                            <p className="card-text">Address: {lab.addressLine1}, {lab.addressLine2}</p>
                            <p className="card-text">{lab.city}, {lab.pincode}, {lab.state}</p>
                          </div>
                        </div>
                      </div>
                    )))}
                  </div>
                  <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
            <div className="row">
                    <div className="col-md-12 d-flex justify-content-end">
                      <button className="btn btn-primary" onClick={() => setStep(2)} disabled={!selectedLab}>Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="row">
            <div className="col-6 mx-xl-auto ">
              <div className="card card-raised">
                <div className="card-body">
                  <div className="card-title mb-2">Book Appointment</div>
                  {selectedLab && (
                    <div>
                      <p>Selected Lab: {selectedLab.labname}</p>
                      <div className="row mt-3">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="date">Select Date</label>
                      <input
                        type="date"
                        id="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                    
                                     </div>
                  </div>
                  )}
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-between">
                      <button className="btn btn-secondary" onClick={() => setStep(1)}>Back</button>
                      <button className="btn btn-primary"  
                     onClick={handleBookAppointment}>Book Appointment</button>
                    </div>
                    </div>
                    </div>
                    </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div className="col-12 mx-xl-auto ps-xl-10" style={{ marginTop: "10%" }}>
        <div className="stepper-wrapper">
          <div className={`stepper-item ${step === 1 ? 'active' : ''}`}>
            <div className="step-counter">1</div>
            <div className="step-name">Select Lab</div>
          </div>
          <div className={`stepper-item ${step === 2 ? 'active' : ''}`}>
            <div className="step-counter">2</div>
            <div className="step-name">Book Appointment</div>
          </div>
        </div>
        {renderStepContent(step)}
        {loader && <div className="loading"></div>}
      </div>

      {/* Modal for 10-hour fasting warning */}
      <Modal show={showWarningModal} onHide={() => setShowWarningModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Please ensure that the employee is fasting for at least 10 hours before taking the test.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="me-3" onClick={() => setShowWarningModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleShowConfirmationModal}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

 <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Consent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        On Confirmation of appointment with the selected lab, I hereby authorize the lab to upload 
        the medical reports to the TMPL web portal for Ashok Leyland.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="me-3" onClick={() => setShowConfirmationModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmBookAppointment}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BookAnAppointment;
