import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
    const currentYear = new Date().getFullYear();


	function cynefianwebsite(e){
		window.open("http://www.cynefian.com","_blank");
	  }

    const [soOption, setSoOption] = useState(
      localStorage.getItem("soOptions") === "true"
    );
  
    const handleToggle = () => {
      setSoOption((prevState) => {
        const newState = !prevState;
        localStorage.setItem("soOptions", newState); // Save to localStorage
        window.location.reload(); // Refresh page
        return newState;
      });
    };
	
    return(
<footer className="p-4">
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between small">
              <div className="me-sm-3 mb-2 mb-sm-0">
                <div className="fw-500 text-white">
                  Copyright &copy;
                  <Link
                    style={{ color: "white", textDecoration: "none" }}
                    onClick={(e) => cynefianwebsite(e)}
                  >
                    {" "}
                    Cynefian
                  </Link>{" "}
                  {currentYear}
                  
                 </div>
             

              </div>
              


              <div className="d-flex align-items-center ms-sm-3">
  {/* Toggle Switch */}
  <span className="form-check form-switch me-3">
    <input
      className="form-check-input"
      type="checkbox"
      id="soOptionToggle"
      checked={soOption}
      onChange={handleToggle}
    />
    <label
      className="form-check-label ms-1 fw-500 text-decoration-none link-white"
      htmlFor="soOptionToggle"
    >
      So Option
    </label>
  </span>
                <Link
                  className="fw-500 text-decoration-none link-white"
                  to="/privacypolicy"
                >
                  Privacy
                </Link>
                <Link
                  className="fw-500 text-decoration-none link-white mx-4"
                  to="/termsandcondition"
                >
                  Terms
                </Link>
                <Link  style={{ cursor: "pointer" }}
              className="fw-500 text-decoration-none link-white"
              to="/documents"
          >
            Help
          </Link>
              </div>
            </div>
            
          </footer>

    )
}
 
export default Footer;