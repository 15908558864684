
import { MdEventNote } from "react-icons/md";
import {  useLocation } from "react-router-dom";
import {  NavLink } from "react-router-dom";


function Sidebar() {

  const location = useLocation();
  const isTestPackagesSectionOpen =
  location.pathname.startsWith("/users/packages")|| 
  location.pathname.startsWith("/users/test-packages")||
  location.pathname.startsWith("/users/test-categories")||
  location.pathname.startsWith("/users/package-designation-map");

  const shouldTestPackagesSectionBeOpen = !isTestPackagesSectionOpen;
  const userRole = JSON.parse(localStorage.getItem("data")).role;
 
  return (
    <div id="layoutDrawer">
       <style>
        {`

          .active-link {
            background-color: #f0f0f0; /* Add your desired active highlight style */
          }

        `}
      </style>
      <div id="layoutDrawer_nav">
        <nav
          className="drawer accordion drawer-light bg-dark"
          id="drawerAccordion"
        >
          
          <div className="drawer-menu" style={{ position: "sticky" }}>
            <div className="nav">
              <div className="drawer-menu-heading d-sm-none">Account</div>
              <a className="nav-link d-sm-none" href="#!">
                <div className="nav-link-icon">
                  <i className="material-icons">notifications</i>
                </div>
                Notifications
              </a>
              <a className="nav-link d-sm-none" href="#!">
                <div className="nav-link-icon">
                  <i className="material-icons">mail</i>
                </div>
                Messages
              </a>

          
              {(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_SUPER") ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/admin-details"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      person  </i>
                    </div>
                    Admin Account
                  </NavLink>
                </div>
              ) : (
                ""
              )}



{(userRole === "ROLE_SUPER" || userRole === "ROLE_ADMIN") && (
  <div>
    <NavLink
      className="nav-link collapsed"
      to="/users/company-details"
      activeClassName="active-link"
    >
      <div className="nav-link-icon">
        <i className="material-icons">business</i>
      </div>
      Company Details
    </NavLink>

    <NavLink
      className="nav-link collapsed"
      to="/users/lab-details"
      activeClassName="active-link"
    >
      <div className="nav-link-icon">
        <i className="material-icons">science</i>
      </div>
      Lab Details
    </NavLink>

    <a
      className={`nav-link collapsed ${isTestPackagesSectionOpen ? "show" : ""}`}
      href="javascript:void(0);"
      data-bs-toggle="collapse"
      data-bs-target="#pagesCollapseAccountsarea"
      aria-expanded={shouldTestPackagesSectionBeOpen ? "true" : "false"}
      aria-controls="pagesCollapseAccountsarea"
    >
      <div className="nav-link-icon">
        <i className="material-icons">science</i>
      </div>
      Test Packages
      <div className="drawer-collapse-arrow">
        <i className="material-icons">expand_more</i>
      </div>
    </a>

    <div
      className={`collapse ${isTestPackagesSectionOpen ? "show" : ""}`}
      id="pagesCollapseAccountsarea"
      data-bs-parent="#drawerAccordion"
    >
      <nav className="drawer-menu-nested nav">
        <NavLink className="nav-link" to="/users/test-categories" activeClassName="active-link">
          Test Categories
        </NavLink>
        <NavLink className="nav-link" to="/users/test-packages" activeClassName="active-link">
          Tests
        </NavLink>
        <NavLink className="nav-link" to="/users/packages" activeClassName="active-link">
          Packages
        </NavLink>
      </nav>
    </div>

    <NavLink
      className="nav-link collapsed"
      to="/users/admin-invoices"
      activeClassName="active-link"
    >
      <div className="nav-link-icon">
        <i className="material-icons">description</i>
      </div>
      Invoices
    </NavLink>

    <NavLink
      className="nav-link collapsed"
      to="/users/reports-approval"
      activeClassName="active-link"
    >
      <div className="nav-link-icon">
        <i className="material-icons">approval</i>
      </div>
      Report Approval
    </NavLink>

    <NavLink
      className="nav-link collapsed"
      to="/users/financials"
      activeClassName="active-link"
    >
      <div className="nav-link-icon">
        <i className="material-icons">description</i>
      </div>
      Financials
    </NavLink>

    <NavLink
      className="nav-link collapsed"
      to="/users/lab-corporate-details"
      activeClassName="active-link"
    >
      <div className="nav-link-icon">
        <i className="material-icons">science</i>
      </div>
      Lab Controller
    </NavLink>
      <NavLink
        className="nav-link collapsed"
        to="/users/settings"
        activeClassName="active-link"
      >
        <div className="nav-link-icon">
          <i className="material-icons">build</i>
        </div>
        Settings
      </NavLink>
      <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/viewReportsByAdmin"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                    <i className="material-icons">insert_chart</i>
                    </div>
                    Reports
                  </NavLink>
  </div>
)}


{(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_SUPER") ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/external-account"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      person  </i>
                    </div>
                    External Account
                  </NavLink>

                 
                </div>
              ) : (
                ""
              )}




{(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_LAB")  ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/employees-appointments"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon">
          <MdEventNote />
        </div>
                    Appointment Details
                  </NavLink>

                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/invoices"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      description  </i>
                    </div>
                    Invoices
                  </NavLink>
                </div>
              ) : (
                ""
              )}

{(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_EMPLOYER")  ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/employeesdetails"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      people  </i>
                    </div>
                    Employees Details
                  </NavLink>

                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/view-doctors"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      people  </i>
                    </div>
                    Doctors Details
                  </NavLink>

                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/company-invoices"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      description  </i>
                    </div>
                    Invoices
                  </NavLink>

                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/view-reports"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                    <i className="material-icons">insert_chart</i>
                    </div>
                    Reports
                  </NavLink>
                </div>
              ) : (
                ""
              )}

{(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_EMPLOYEE")  ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/employee-appointments"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon">
          <MdEventNote />
        </div>
                    Appointment Details
                  </NavLink>

                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/family-details"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      people  </i>
                    </div>
                    Family Details
                  </NavLink>
                </div>
              ) : (
                ""
              )}

{(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_DOCTOR")  ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/companyEmployeesDetails"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      people  </i>
                    </div>
                    Employees Details
                  </NavLink>
                </div>
              ) : (
                ""
              )}

{(`${JSON.parse(localStorage.getItem("data")).role}` ===
                "ROLE_LAB_CORPORATE")  ? (
                <div>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/corporate-lab-details"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      science  </i>
                    </div>
                    Lab Details
                  </NavLink>
                  <NavLink
                    className="nav-link collapsed"
                    href="javascript:void(0);"
                    data-bs-target="#collapseDashboards"
                    aria-controls="collapseDashboards"
                    to="/users/view-all-invoices"
                    activeClassName="active-link"
                  >
                    <div className="nav-link-icon" >
                      <i className="material-icons">
                      description  </i>
                    </div>
                    Invoices
                  </NavLink>
                </div>
              ) : (
                ""
              )}

              <div
                className="collapse"
                id="collapseDashboards"
                aria-labelledby="headingOne"
                data-bs-parent="#drawerAccordion"
              ></div>
            </div>
          </div>

          <div className="drawer-footer border-top">
            <div className="d-flex align-items-center">
              <div className="text-center">
                {/* <img
                  className="img-fluid rounded-circle mb-1"
                  src={`${profileimage.imageMeta},${profileimage.image}`}
                /> */}
              </div>
              <div className="caption">Logged in as:</div>
              <div className="ms-3">
                <div className="small fw-500">
                  {JSON.parse(localStorage.getItem("data")).username}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
