import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const ViewOrUpdateBody = ({ isOpen, onRequestClose, apiUrlId, contextType }) => {
  const navigate = useNavigate();
  const textareaRef = useRef(null); // To track the textarea for cursor position

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(""); // For plain text content
  const [selectedFieldType, setSelectedFieldType] = useState("");

  const fieldPlaceholders = {
    username: "{username}",
    password: "{password}",
    token: "{token}",
    key: "{key}",
    value: "{value}",
    clientId: "{client-code}",
    testId: "{test-id}",
    labcode: "{lab-code}",
    labname: "{lab-name}",
    address: "{lab-address}",
    phone: "{phone-number}",
    email: "{email}",
    companycode: "{company-code}",
    name: "{name}",
    salutation: "{salutation}",
    firstname: "{firstname}",
    lastname: "{lastname}",
    dob: "{dob}",
    gender: "{gender}",
  };

  // Fetch existing content if the modal is open and apiUrlId is provided
  useEffect(() => {
    if (isOpen && apiUrlId) {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data"))?.token;

      axios
        .get(`/api/api-url-settings/${apiUrlId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            setContent(response.data);
          }
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isOpen, apiUrlId]);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function handleFieldTypeChange(e) {
    const selectedField = e.target.value;
    setSelectedFieldType(selectedField);
  
    // Get the placeholder value for the selected field
    const placeholder = fieldPlaceholders[selectedField] || "";
  
    // Insert the placeholder at the cursor position in the textarea
    insertPlaceholderAtCursor(placeholder);
  }
  
  function insertPlaceholderAtCursor(placeholder) {
    const textarea = textareaRef.current;
  
    // Check if the textarea reference is valid
    if (!textarea) return;
  
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
  
    // Create a new variable to hold the updated content
    let currentContent = "";
  
    // Safely extract the relevant content based on context type
    switch (contextType) {
      case "createBooking":
        currentContent = content.createBody || "";
        break;
      case "orderStatus":
        currentContent = content.orderStatusBody || "";
        break;
      case "reports":
        currentContent = content.reportsBody || "";
        break;
      case "results":
        currentContent = content.resultsBody || "";
        break;
      default:
        currentContent = ""; // Fallback to empty string if context type is unknown
    }
  
    // Create new content by replacing the selected text or inserting at the cursor
    const newContent =
      currentContent.substring(0, startPos) + // Text before the cursor
      placeholder + // Placeholder to insert
      currentContent.substring(endPos); // Text after the cursor
  
    // Update the content state based on context type
    setContent((prevContent) => {
      return {
        ...prevContent,
        [contextType === "createBooking" ? "createBody" :
          contextType === "orderStatus" ? "orderStatusBody" :
          contextType === "reports" ? "reportsBody" :
          contextType === "results" ? "resultsBody" : ""]: newContent
      };
    });
  
    // Set the cursor position after the inserted text
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + placeholder.length;
      textarea.focus(); // Ensure the textarea is focused
    }, 0);
  }
  
  
  

  function saveContent() {
  const token = JSON.parse(localStorage.getItem("data"))?.token;
  setLoading(true);

  if (!apiUrlId) {
    toast.error("API URL ID is missing. Cannot save content.");
    return;
  }

  try {
    // Construct request body based on context type
    const requestBody = {
      id: apiUrlId,
      ...(contextType === "createBooking" ? { createBody: content.createBody || "" } : {}),
      ...(contextType === "orderStatus" ? { orderStatusBody: content.orderStatusBody || "" } : {}),
      ...(contextType === "reports" ? { reportsBody: content.reportsBody || "" } : {}),
      ...(contextType === "results" ? { resultsBody: content.resultsBody || "" } : {}),
    };

    axios
      .post(`/api/api-url-settings/update`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Content saved successfully!");
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      });
  } catch (error) {
    setLoading(false);
    handleError(error);
  }
}

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>View/Update Body</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}

        <Form.Group>
          <label htmlFor="fields">Select fields:</label>
          <select
            id="fields"
            value={selectedFieldType}
            onChange={handleFieldTypeChange}
            className="form-select"
          >
            <optgroup label="Auth Fields">
              <option value="username">Username</option>
              <option value="password">Password</option>
              <option value="token">Token</option>
              <option value="key">Key</option>
              <option value="value">Value</option>
            </optgroup>
            <optgroup label="Lab Fields">
              <option value="clientId">Client Id</option>
              <option value="testId">Test Id</option>
              <option value="labcode">Lab Code</option>
              <option value="labname">Lab Name</option>
              <option value="address">Address</option>
              <option value="phone">Phone Number</option>
              <option value="email">Email Id</option>
            </optgroup>
            <optgroup label="Employer Fields">
              <option value="companycode">Org Code</option>
              <option value="name">Name</option>
              <option value="address">Address</option>
              <option value="phone">Phone Number</option>
              <option value="email">Email Id</option>
            </optgroup>
            <optgroup label="Employee Fields">
              <option value="salutation">Salutation</option>
              <option value="firstname">First Name</option>
              <option value="lastname">Last Name</option>
              <option value="phone">Phone Number</option>
              <option value="email">Email Id</option>
              <option value="address">Address</option>
              <option value="dob">DoB</option>
              <option value="gender">Gender</option>
            </optgroup>
          </select>
          </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Enter Body:</Form.Label>
         <textarea
  ref={textareaRef}
  className="form-control"
  rows="20"
  value={
    contextType === "createBooking" ? content.createBody :
    contextType === "orderStatus" ? content.orderStatusBody :
    contextType === "reports" ? content.reportsBody :
    contextType === "results" ? content.resultsBody : ""
  }
  onChange={(e) => setContent((prev) => ({
    ...prev,
    [contextType === "createBooking" ? "createBody" :
      contextType === "orderStatus" ? "orderStatusBody" :
      contextType === "reports" ? "reportsBody" :
      contextType === "results" ? "resultsBody" : ""]: e.target.value
  }))}
/>

        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary me-3" onClick={saveContent}>
          SAVE
        </Button>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewOrUpdateBody;
