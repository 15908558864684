import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ViewOrUpdateBody from "./viewOrUpdateBody";

function ApiUrlSettings() {
  const { orgcode } = useParams();
  const [labCorporateDetails, setLabCorporateDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [authMethods, setAuthMethods] = useState([]);
  const [authFields, setAuthFields] = useState({});
  const [authMethod, setAuthMethod] = useState("");
  const [apiUrlId, setApiUrlId] = useState(null);
  const navigate = useNavigate();
  const [modalContext, setModalContext] = useState("");

  const [apiUrls, setApiUrls] = useState({
    apiUrlSettings: {
      baseApiUrl: "",
      authMethodId: "",
      createBookingContext: "",
      orderStatusContext: "",
      reportsContext: "",
      resultsContext: "",
      labCorporateId: ""
    },
    authValues: [],
  });

  const getAuthMethods = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/authentication-methods`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAuthMethods(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const trans = await axios.get(`/api/lab-corporate/orgcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLabCorporateDetails(trans.data);
      setIsApiUrlSettingsEnabled(trans.data.apiUrlSettings);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const fetchApiUrlSettings = async (labCorporateId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/get-api-url/labcorporateid=${labCorporateId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data) {
        setAuthMethod(response.data.apiUrlSettings.authMethodId);
        const authRes = await axios.get(`/api/auth-required-values/authtypeid=${response.data.apiUrlSettings.authMethodId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAuthFields(authRes.data || {});

        setApiUrls((prevState) => ({
          ...prevState,
          apiUrlSettings: {
            ...prevState.apiUrlSettings,
            ...response.data.apiUrlSettings,
          },
          authValues: response.data.authValues || [],
        }));
        setApiUrlId(response.data.apiUrlSettings.id);
        
      } else {
        setApiUrls((prevState) => ({
          ...prevState,
          apiUrlSettings: {
            baseApiUrl: "",
            authMethodId: "",
            createBookingContext: "",
            orderStatusContext: "",
            reportsContext: "",
            resultsContext: "",
            labCorporateId: labCorporateId
          },
          authValues: [],
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else {
      toast.error("An error occurred.");
    }
  };

  const handleAuthMethodChange = async (e) => {
    const selectedAuthMethod = e.target.value;
    setAuthMethod(selectedAuthMethod);
    
    setApiUrls((prevState) => ({
      ...prevState,
      apiUrlSettings: {
        ...prevState.apiUrlSettings,
        authMethodId: selectedAuthMethod,
        labCorporateId: labCorporateDetails.id
      },
      authValues: []
    }));

    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(`/api/auth-required-values/authtypeid=${selectedAuthMethod}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAuthFields(response.data || {});
    } catch (error) {
      handleError(error);
    }
  };

  const handleViewOrUpdateBody = (contextType) => {
    setIsModalOpen(true);
    setModalContext(contextType);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  useEffect(() => {
    getData();
    getAuthMethods();
  }, []);

  useEffect(() => {
    if (labCorporateDetails.id) {
      fetchApiUrlSettings(labCorporateDetails.id);
    }
  }, [labCorporateDetails]);

  const rhandle = (e) => {
    const { id, value } = e.target;
    setApiUrls((prevState) => ({
      ...prevState,
      apiUrlSettings: {
        ...prevState.apiUrlSettings,
        [id]: value,
      },
    }));
  };

  const handleAuthValueChange = (id, newValue) => {
    setApiUrls(prevState => ({
      ...prevState,
      authValues: prevState.authValues.map(auth =>
        auth.requiredValuesId === id
          ? { ...auth, authValues: newValue } // Update the existing entry
          : auth
      ).concat(!prevState.authValues.find(auth => auth.requiredValuesId === id) 
        ? [{ requiredValuesId: id, authValues: newValue }] // Add a new entry if not found
        : [])
    }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const response = await axios.post(`/api/apiUrlSettings/createOrUpdate`, apiUrls, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        toast.success("API Url settings saved successfully.");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const [isApiUrlSettingsEnabled, setIsApiUrlSettingsEnabled] = useState(false);

  const handleSwitchChange = async (e) => {
    const isChecked = e.target.checked;
    setIsApiUrlSettingsEnabled(isChecked);

    // Trigger API to update the value of `apiUrlSettings` on the backend
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.post(`/api/lab-corporate/update`, {
        id: labCorporateDetails.id,
        apiUrlSettings: isChecked
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("updated successfully.");
      getData();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div>
      <div className="container mt-4">
        {loader && <div className="loading"></div>}

        <div className="form-check form-switch">
          <label className="form-check-label" htmlFor="apiUrlSettingsSwitch">
           API Url Settings
          </label>
          <input
            type="checkbox"
            className="form-check-input"
            id="apiUrlSettingsSwitch"
            checked={isApiUrlSettingsEnabled}
            onChange={handleSwitchChange}
          />
        </div>

        {isApiUrlSettingsEnabled && (
        
        <form onSubmit={handleSubmit}>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td style={{ width: "200px" }}>
                  <label htmlFor="baseApiUrl">Base API URL:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="baseApiUrl"
                    className="form-control"
                    value={apiUrls.apiUrlSettings.baseApiUrl}
                    onChange={rhandle}
                    placeholder="Enter Base API URL"
                    style={{ width: "300px" }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="authMethodId">Authentication Method:</label>
                </td>
                <td>
                  <select
                    id="authMethodId"
                    className="form-select"
                    value={apiUrls.apiUrlSettings.authMethodId}
                    onChange={handleAuthMethodChange}
                    style={{ width: "300px" }}
                  >
                    <option value="">Select Authentication Method</option>
                    {authMethods.map((method) => (
                      <option key={method.id} value={method.id}>
                        {method.authenticationType}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>

            {authMethod && (
  <>
    {Object.keys(authFields).length > 0 ? (
      Object.entries(authFields).map(([key, field]) => (
        <tr key={field.requiredValuesId}>
          <td>
            <label htmlFor={field.id}>{field.requiredValues}:</label>
          </td>
          <td>
            <input
              type="text"
              id={field.id}
              className="form-control"
              placeholder={field.placeholder}
              style={{ width: "300px" }}
              value={
                apiUrls.authValues.find(auth => auth.requiredValuesId === field.id)?.authValues || ''
              }
              onChange={(e) => handleAuthValueChange(field.id, e.target.value)}
            />
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="2">No additional fields required for authentication.</td>
      </tr>
    )}
  </>
)}


              <tr>
                <td>
                  <label htmlFor="createBookingContext">Create Booking Context:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="createBookingContext"
                    className="form-control"
                    value={apiUrls.apiUrlSettings.createBookingContext}
                    onChange={rhandle}
                    placeholder="Enter Booking Context"
                    style={{ width: "300px" }}
                  />
                </td>
                <td>
                  <button type="button" className="btn btn-link" onClick={() => handleViewOrUpdateBody("createBooking")}>
                    View/Update body
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="orderStatusContext">Order Status Context:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="orderStatusContext"
                    className="form-control"
                    value={apiUrls.apiUrlSettings.orderStatusContext}
                    onChange={rhandle}
                    placeholder="Enter Order Status Context"
                    style={{ width: "300px" }}
                  />
                </td>
                <td>
                <button type="button" className="btn btn-link" onClick={() => handleViewOrUpdateBody("orderStatus")}>
                    View/Update body
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="reportsContext">Reports Context:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="reportsContext"
                    className="form-control"
                    value={apiUrls.apiUrlSettings.reportsContext}
                    onChange={rhandle}
                    placeholder="Enter Reports Context"
                    style={{ width: "300px" }}
                  />
                </td>
                <td>
                <button type="button" className="btn btn-link" onClick={() => handleViewOrUpdateBody("reports")}>
                    View/Update body
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <label htmlFor="resultsContext">Results Context:</label>
                </td>
                <td>
                  <input
                    type="text"
                    id="resultsContext"
                    className="form-control"
                    value={apiUrls.apiUrlSettings.resultsContext}
                    onChange={rhandle}
                    placeholder="Enter Results Context"
                    style={{ width: "300px" }}
                  />
                </td>
                <td>
                <button type="button" className="btn btn-link" onClick={() => handleViewOrUpdateBody("results")}>
                    View/Update body
                  </button>
                </td>
              </tr>

              <tr>
                <td colSpan="2" className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        )}
  
        {
  isModalOpen && (
      <ViewOrUpdateBody
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        apiUrlId={apiUrlId}
        contextType={modalContext}
      />
)}
      </div>
    </div>
  );
}

export default ApiUrlSettings;
