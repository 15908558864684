import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";
import DisclaimerPopup from "./disclaimerPopup";
import AppointmentReschedule from "./appointmentReschedule";
import { Pagination } from "react-bootstrap";
import { FaRedoAlt } from 'react-icons/fa';

function EmployeeAppointments() {
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isReschedulePopupOpen, setIsReschedulePopupOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  
  const navigate = useNavigate();
  const userid = JSON.parse(localStorage.getItem("data")).userid;

  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const searchUrl = `/api/employeeappointment/userid=${userid}`;
      const response = await axios.get(searchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setAppointmentDetails(response.data);
      // setFilteredData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const getData = async (pageNumber = 0) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const trans = await axios.get(`/api/employeeappointment/getAllAppointmentsByUserid/userid=${userid}`, {
        params: { page: pageNumber },
        headers: { Authorization: `Bearer ${token}` },
      });

      setAppointmentDetails(trans.data.content);
      // setFilteredData(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  const handleCancelAppointment = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try{
      await axios({
      url: "/api/employeeappointment/update",
      method: "post",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${appointmentId}`,
        appointmentstatus:"APPOINTMENT CANCELLED"
      },
    });
    toast.success("Appointment Cancelled successfully");
      getData();
    } catch (error) {
      handleError(error);
      setLoader(false);
    }
  };

  // const handleBookAppointment = () => {
  //   navigate('/users/book-appointment');
  // };

  const [pdfUrl, setPdfUrl] = useState(null);
  const handleViewPdf = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/employeeappointment/download/id=${appointmentId}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  }
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const handleAcceptAppointment = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      await axios.post(
        "/api/employeeappointment/accept-or-reschedule",
        {
          id: `${appointmentId}`,
          appointmentstatus: "APPOINTMENT CONFIRMED",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Employee Appointment Confirmed successfully.");
      getData();
      setLoader(false);
    } catch (error) {
    setLoader(false);
      handleError(error);
 
    }
  };

  const handleRetry = async (appointment) => {
    setLoader(true); 
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      await axios.post('/api/employeeappointment/re-trigger-api', appointment, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Retried successfully');
      getData();
    } catch (error) {
      toast.error('Retry failed');
    } finally {
      setLoader(false); 
    }
  };

  const handleFetchReport= async (appointment) => {
    setLoader(true); 
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      await axios.post('/api/employeeappointment/fetchReport', appointment, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Report fetched successfully');
      getData();
    } catch (error) {
     handleError(error);
    } finally {
      setLoader(false); 
    }
  };

  const handleRescheduleAppointment = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsReschedulePopupOpen(true);
  };

  const handleCloseReschedulePopup = () => {
    setIsReschedulePopupOpen(false);
    getData();
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


 
  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>My Appointment Details</span>
              <button className="btn btn-primary" onClick={handleShowImageModal}  
              // disabled={employeeDetails.disabled} 
              // disabled={employeeDetails.annualCheckup || employeeDetails.disabled} 
              >
                Book Appointment
              </button>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Date</th>
                  {/* <th>Time</th> */}
                  <th>Lab Name</th>
                  <th>Lab Contact Number</th>
                  <th>Package Name</th>
                  <th>Appointment Status</th>
                  <th>Report</th>
                  <th>Lab Order Status</th>
                </tr>
              </thead>
              <tbody>
                {appointmentDetails.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  appointmentDetails.map((appointment) => (
                    <tr key={appointment.id}>
                      <td>{appointment.date}</td>
                      {/* <td>{appointment.time}</td> */}
                      <td>{appointment.labs.labname}</td>
                      <td>{appointment.labs.contact}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.packageid)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td>
  <div>{appointment.appointmentstatus}</div>
  {(appointment.appointmentstatus!=='APPOINTMENT CANCELLED'||appointment.appStatus?.appointmentStatus !== 'APPOINTMENT CANCELLED') && (appointment.appointmentstatus!=='APPOINTMENT COMPLETED'||appointment.appStatus?.appointmentStatus !== 'APPOINTMENT COMPLETED') && (
  // {appointment.appointmentstatus !== 'CANCELLED' && appointment.appointmentstatus !== 'COMPLETED' && (
  <>
    <div>
      {/* Show the Accept button if the appointment is rescheduled by Lab and not yet confirmed */}
      {appointment.appStatus?.appointmentStatus !== 'APPOINTMENT CONFIRMED' && 
        appointment.appStatus?.appointmentStatus === 'APPOINTMENT RESCHEDULED' && 
        appointment.appStatus?.updatedBy !== 'Employee' && (
        <button
          className="btn btn-success btn-sm rounded-pill me-2"
          onClick={() => handleAcceptAppointment(appointment.id)}
        >
          Accept
        </button>
      )}
      
      {/* Cancel button, displayed only if the appointment is neither CANCELLED nor COMPLETED */}
      <button
        className="btn btn-danger btn-sm rounded-pill"
        onClick={() => handleCancelAppointment(appointment.id)}
      >
        Cancel
      </button>
    </div>
    
    <div className="mt-2">
      <button
        className="btn btn-warning btn-sm rounded-pill"
        onClick={() => handleRescheduleAppointment(appointment.id)}
      >
        Reschedule
      </button>
    </div>
  </>
)}

</td>
<td>
  {appointment.orderStatus ? (
    appointment.orderStatus?.appointmentReport ? (
      <div>
        {/* Display the appointment report if available */}
        <span>{appointment.orderStatus.appointmentReport}</span>
      </div>
    ) : (
      <button
        onClick={() => handleFetchReport(appointment)}
      >
        Fetch Report
      </button>
    )
  ) : (
    appointment.file ? (
      appointment.reportstatus === 'APPROVED' ? (
        <button
          className="btn btn-primary"
          onClick={() => handleViewPdf(appointment.id)}
        >
          View
        </button>
      ) : (
        "Approval Pending"
      )
    ) : (
      "No Report Added"
    )
  )}
</td>

<td>
  <div>{appointment.orderStatus?.status}</div>

  <div>
    {appointment.orderStatus ? (
      <>
      <br/>
        {appointment.orderStatus?.orderId === "" || appointment.orderStatus?.orderId == null ? (  // Check for both empty string and null/undefined
          <>
            <button
              onClick={() => handleRetry(appointment)}
              style={{ cursor: 'pointer', color: 'red' }}
            >
              Retry
              <FaRedoAlt />
            </button>
          </>
        ) : (
          <div>{appointment.orderStatus?.orderId}</div>
        )}
      </>
    ) : (
      <div>NA</div>
    )}
  </div>
</td>


                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
          </div>
          {isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}

{showImageModal && (
           <DisclaimerPopup isOpen={showImageModal} onRequestClose={handleCloseImageModal} userid={userid}/>
           )}
        </div>
        {isReschedulePopupOpen && (
          <AppointmentReschedule
            isOpen={isReschedulePopupOpen}
            onRequestClose={handleCloseReschedulePopup}
            appointmentId={selectedAppointmentId}
          />
        )}
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default EmployeeAppointments;
