import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import "./Spinner.css";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/tmplLogo.png";
function DownloadDocuments() {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
  
    const getData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/documentation`, {
        });
        setDocuments(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
  
    const handleError = (error) => {
      if (error.response && error.response.status === 401) {
        navigate("/signin");
        toast.error("Your session has expired. Please login.");
      } else {
        toast.error(
          error.response?.data?.message || error.response?.data?.code || "An error occurred."
        );
      }
    };
  
    const handleDownload = (fileMeta, fileContent, fileName) => {
        const linkSource = `data:${fileMeta};base64,${fileContent}`; // Assuming base64 encoded data
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName || "document.pdf"; // Use provided fileName or default
        downloadLink.click();
      };
      
    useEffect(() => {
      getData();
    }, []);
  
    return (
      <>
      <div className="col-xl-10 mx-xl-auto">
        <div className="card card-raised mb-3">
          <div className="card-body p-4 ">
            <div className="col-xl-12 mx-xl-auto">
              <div className="card card-raised mb-3">
                <div
                  className="card-body p-3 bg-primary"
                 
                >
                  <div
                    className="card-title d-flex"
                  >
                    {" "}
                    <img
                      class="mb-2"
                      src={logo}
                      alt="..."
                      style={{ height: "40px", position: "relative" }}
                    />
                    <h4
                      style={{
                        position: "relative",
                        left: "280px",
                        top: "10px",
                        color: "white",
                      }}
                    >
                      Documents
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          {loading && <div className="loading"></div>}
          <table className="table table-bordered">
          <thead>
          <tr>
                  <th>Document Name</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                {documents.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No Documents Available
                    </td>
                  </tr>
                ) : (
                  documents.map((doc) => (
                    <tr key={doc.id}>
                      <td>{doc.fileName}</td>
                      <td> <button
       
        style={{ cursor: "pointer" }}
        onClick={() => handleDownload(doc.fileMeta, doc.file, doc.fileName)}
      >
        <FontAwesomeIcon icon={faDownload} /> Download
      </button></td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
       </div>
       </div>
       </div>
       </>
    );
  };
  
  export default DownloadDocuments;
  