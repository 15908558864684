import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import UploadReport from "./uploadReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";
import FamilyAppointmentCancel from "./familyAppointmentCancelPopup";
import AppointmentReschedule from "./appointmentReschedule";
import { Pagination } from "react-bootstrap";

function ViewAppointmentsByLabCorporate({ labcode }) {
  // const {labcode} =useParams();
  console.log("l",labcode);

  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isReschedulePopupOpen, setIsReschedulePopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const searchUrl = `/api/employeeappointment/labcode=${labcode}`;
      const response = await axios.get(searchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setAppointmentDetails(response.data);
      // setFilteredData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const getData = async (pageNumber = 0) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const trans = await axios.get(`/api/employeeappointment/getAllAppointments/labcode=${labcode}`, {
        params: { page: pageNumber },
        headers: { Authorization: `Bearer ${token}` },
      });

      setAppointmentDetails(trans.data.content);
      // setFilteredData(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  useEffect(() => {
    if (appointmentDetails.length > 0) {
      const filtered = appointmentDetails.filter(
        (appointment) =>
          (appointment.company?.companyname?.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (appointment.pack?.packagename?.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (String(appointment.emplo?.phoneno)?.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, appointmentDetails]);


  const handleCancelClick = async (appointment) => {
    setSelectedAppointment(appointment)
    setIsCancelPopupOpen(true);
  };
  
  const handleCloseCancelPopup = () => {
    setIsCancelPopupOpen(false);
    getData();
  };


  const handleUploadReport = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    getData();
  };
 
  const [pdfUrl, setPdfUrl] = useState(null);
  const handleViewPdf = async (appointmentId) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  await axios({
    url: `/api/employeeappointment/download/id=${appointmentId}`,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    console.log("filedata;", response);
    const contentType = response.headers["content-type"];
    let extension = "";

    // Determine the file extension based on content type
    if (contentType.includes("application/pdf")) {
      extension = "pdf";
    } else if (contentType.includes("image/jpeg")) {
      extension = "jpg";
    } else if (contentType.includes("image/png")) {
      extension = "png";
    } else {
      console.error("Unsupported file type:", contentType);
      return;
    }

    // Create a blob URL and download link for the file
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `file.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  const handleAcceptAppointment = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      await axios.post(
        "/api/employeeappointment/accept-or-reschedule",
        {
          id: `${appointmentId}`,
          appointmentstatus: "APPOINTMENT CONFIRMED",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Employee Appointment Confirmed successfully.");
      getData();
      setLoader(false);
    } catch (error) {
    setLoader(false);
      handleError(error);
 
    }
  };

  const handleRescheduleAppointment = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsReschedulePopupOpen(true);
  };

  const handleCloseReschedulePopup = () => {
    setIsReschedulePopupOpen(false);
    getData();
  };

  const handleBack = () => {
    navigate(-1);
   };

   const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


  return (
    <div>
        {loading && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto">
      <div className="mb-2">
                      <button className="btn btn-primary" onClick={handleBack}>
                        Back
                      </button>
                    </div>
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Appointment Details</span>
              <div className="form-group position-relative" style={{ width: '300px' }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by company code or phone number or Package"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ paddingLeft: "2rem", width: "100%" }}
                />
                <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Name</th>
                  <th>Date</th>
                  {/* <th>Time</th> */}
                  <th>Company</th>
                  <th>Contact Number</th>
                  <th>Package Name</th>
                  <th>Status</th>
                  <th>Report</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  filteredData.map((appointment) => (
                    <tr key={appointment.id}>
                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        title="Upload Report"
                        onClick={() => handleUploadReport(appointment.id)}
                        onMouseOver={(e) => {
                          e.target.style.color = "blue";
                          e.target.style.textDecoration = "underline";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "";
                          e.target.style.textDecoration = "";
                        }}
                      >
                         {appointment.fam?.name ? (
                          appointment.fam.name
                        ) : (
                          <>
                          {appointment.emplo?.name} {appointment.emplo?.lastname}
                        </>
                      )}
                      </td>
                      <td>{appointment.date}</td>
                      {/* <td>{appointment.time}</td> */}
                      <td>{appointment.company.companyname}</td>
                      <td>{appointment.emplo?.phoneno}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.packageid)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td>
  <div>{appointment.appointmentstatus}</div>
  {(appointment.appointmentstatus!=='APPOINTMENT CANCELLED'||appointment.appStatus?.appointmentStatus !== 'APPOINTMENT CANCELLED') && (appointment.appointmentstatus!=='APPOINTMENT COMPLETED'||appointment.appStatus?.appointmentStatus !== 'APPOINTMENT COMPLETED') && (
  // {appointment.appointmentstatus !== 'APPOINTMENT CANCELLED' && appointment.appointmentstatus !== 'COMPLETED' && (
  <>
    <div>
      {(appointment.appStatus?.appointmentStatus === 'APPOINTMENT CONFIRMATION PENDING WITH LAB')|| 
       (appointment.appStatus?.appointmentStatus === 'APPOINTMENT RESCHEDULED' && 
        (appointment.appStatus?.updatedBy !== 'Lab')&&(appointment.appStatus?.updatedBy !== 'Lab Corporate')) ? (
        <button
          className="btn btn-success btn-sm rounded-pill me-2"
          onClick={() => handleAcceptAppointment(appointment.id)}
        >
          Accept
        </button>
      ) : null}

      <button
        className="btn btn-danger btn-sm rounded-pill"
        onClick={() => handleCancelClick(appointment)}
      >
        Cancel
      </button>
    </div>
    <div className="mt-2">
      <button
        className="btn btn-warning btn-sm rounded-pill"
        onClick={() => handleRescheduleAppointment(appointment.id)}
      >
        Reschedule
      </button>
    </div>
  </>
)}
</td>

                      <td>
                        {appointment.file ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewPdf(appointment.id)}
                          >
                            View
                          </button>
                        ) : (
                          "No Report Added"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
          </div>
        </div>
        {isUploadModalOpen && (
          <UploadReport
            isOpen={isUploadModalOpen}
            onRequestClose={handleCloseUploadModal}
            empId={selectedAppointmentId}
          />
        )}

{isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}

{isCancelPopupOpen &&(
   <FamilyAppointmentCancel
   isOpen={isCancelPopupOpen}
   onRequestClose={handleCloseCancelPopup}
   appointment={selectedAppointment}
 />
)}
  {isReschedulePopupOpen && (
          <AppointmentReschedule
            isOpen={isReschedulePopupOpen}
            onRequestClose={handleCloseReschedulePopup}
            appointmentId={selectedAppointmentId}
          />
        )}

        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default ViewAppointmentsByLabCorporate;
